import React from "react";

export default function index() {
  return (
    <div>
      Please email{" "}
      <a href="mailto: domain.inquiry.services@gmail.com">
        domain.inquiry.services@gmail.com
      </a>{" "}
      if you'd like to inquire about purchasing this domain. Thank you!
    </div>
  );
}
